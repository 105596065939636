import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, EMPTY, Observable, throwError } from 'rxjs';
import { inject } from '@angular/core';
import { ErrorResponseHttpModel } from '../../interfaces/error.interface';
import { ErrorService } from '../error.service';

function handle401Error(
  errorService: ErrorService,
  errorResponse: ErrorResponseHttpModel,
): Observable<never> {
  if (errorResponse.code === 'required_claim_not_found') {
    errorService.addError('required_claim_not_found', errorResponse);
    return EMPTY;
  }

  if (errorResponse.code === 'no_product_found') {
    errorService.addError('no_product_found', errorResponse);
    return EMPTY;
  }

  return handleDefaultError(errorService, errorResponse, 401);
}

function handle404Error(
  errorService: ErrorService,
  errorResponse: ErrorResponseHttpModel,
): Observable<never> {
  if (errorResponse.code === 'user_not_found') {
    errorService.addError('user_not_found', errorResponse);
    return EMPTY;
  }

  return handleDefaultError(errorService, errorResponse, 404);
}

function handleDefaultError(
  errorService: ErrorService,
  error: ErrorResponseHttpModel,
  status: number,
): Observable<never> {
  console.error(`Error ${status}:`, error.message);
  errorService.addError('unknown_error', error);
  return throwError(() => error);
}

export const errorInterceptorFn: HttpInterceptorFn = (req, next) => {
  const _errorService = inject(ErrorService);
  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse && error.error) {
        const errorResponse: ErrorResponseHttpModel = error.error;

        switch (error.status) {
          case 401:
            return handle401Error(_errorService, errorResponse);
          case 404:
            return handle404Error(_errorService, errorResponse);
          default:
            return handleDefaultError(_errorService, error.error, error.status);
        }
      }

      console.error('unknown error', error);
      return throwError(() => error);
    }),
  );
};
