import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideMaterialDefaults } from '@acacium-group/ui/providers';

import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';

import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { environment } from '../environment/environment';
import { routes } from './app.routes';
import { errorInterceptorFn } from './services/interceptors/error.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    provideAuth0({ ...environment.auth0 }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.pwa,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideHttpClient(
      withInterceptors([authHttpInterceptorFn, errorInterceptorFn]),
    ),
    provideMaterialDefaults(),
  ],
};
