import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'selector',
  },
  {
    path: 'selector',
    loadComponent: () =>
      import('./components/selector/selector.component').then(
        (m) => m.SelectorComponent,
      ),
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'callback',
    loadComponent: () =>
      import('./components/callback/callback.component').then(
        (m) => m.CallbackComponent,
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./components/not-found/not-found.component').then(
        (m) => m.NotFoundComponent,
      ),
  },
];
