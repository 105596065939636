import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  DashboardError,
  DashboardErrorType,
  ErrorResponseHttpModel,
} from '../interfaces/error.interface';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private _errors: Array<DashboardError> = [];
  private _errorsSubject = new BehaviorSubject<Array<DashboardError> | null>(
    null,
  );

  public errors$ = this._errorsSubject.asObservable();

  public addError(
    name: DashboardErrorType,
    error: ErrorResponseHttpModel,
  ): void {
    this._errors.push({ name, error });
    this._errorsSubject.next(this._errors);
  }

  public removeError(name: DashboardErrorType): void {
    this._errors = this._errors.filter((error) => error.name !== name);
    if (this._errors.length === 0) {
      this._errorsSubject.next(null);
      return;
    }
    this._errorsSubject.next(this._errors);
  }
}
