<div class="error">
  <h1 class="error__title">Something went wrong!</h1>
  @if (errors$ | async; as errors) {
    @for (error of errors; track error.name) {
      @switch (error.name) {
        @case ('required_claim_not_found') {
          <ng-container *ngTemplateOutlet="universalError"></ng-container>
        }
        @case ('no_product_found') {
          <ng-container *ngTemplateOutlet="universalError"></ng-container>
        }
        @case ('user_not_found') {
          <ng-container *ngTemplateOutlet="universalError"></ng-container>
        }
        @default {
          <div class="error__content">
            {{ error.error.message }}
          </div>
        }
      }
    }
  }
</div>

<ng-template #universalError>
  <div class="error__content">
    Unfortunately we cannot find an active account for this particular portal
    and company. Please check you're using the correct login details, or link
    and try again.
    <br />
    Alternatively, if you need help please call us on the telephone number for
    your area.
    <br />
    <br />
    Thornbury Nursing Services -
    <a class="text-link" href="tel:0345 120 5251">0345 120 5251</a>
    <br />
    Scottish Nursing Guild, Scotland -
    <a class="text-link" href="tel:0131 221 7178">0131 221 7178</a>
    <br />
    Scottish Nursing Guild, Northern Ireland -
    <a class="text-link" href="tel:0131 221 7178">0131 221 7178</a>
    <br />
    Scottish Nursing Guild, Republic of Ireland -
    <a class="text-link" href="tel:01 902 3083">01 902 3083</a>
  </div>
</ng-template>
