<div class="app">
  @if (isAuthenticated$ | async) {
    <header class="app__header">
      <button mat-flat-button agTheme (click)="logOut()" class="small">
        Logout
      </button>
    </header>
    <main class="app__wrapper">
      <div class="app__content">
        @if (errors$ | async) {
          <app-error></app-error>
        } @else {
          <router-outlet></router-outlet>
        }
      </div>
    </main>
  } @else {
    <app-loader></app-loader>
  }
</div>
