import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe, NgIf } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { AgThemeDirective } from '@acacium-group/ui/directives';
import { AuthService } from '@auth0/auth0-angular';
import { take } from 'rxjs';
import { environment } from '../environment/environment';

import { LoaderComponent } from './components/loader/loader.component';
import { ErrorService } from './services/error.service';
import { ErrorComponent } from './components/error/error.component';
import { GoogleTagManagerService } from './services/google-tag-manager.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    NgIf,
    LoaderComponent,
    ErrorComponent,
    MatButton,
    AgThemeDirective,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private _authService = inject(AuthService);
  private _errorService = inject(ErrorService);
  private _gtmService = inject(GoogleTagManagerService);

  public isAuthenticated$ = this._authService.isAuthenticated$;
  public errors$ = this._errorService.errors$;

  public ngOnInit(): void {
    this._gtmService.setConfig({
      id: environment.gtmId,
    });
    this._gtmService.addGtmToDom().pipe(take(1)).subscribe();
  }

  public logOut(): void {
    this._authService.logout({
      logoutParams: { returnTo: window.location.origin },
    });
  }
}
